import apiAction from 'common/actions/api'

export const CHANGE_ATTRIBUTE_FILTER =
  'cylera/custom_attributes/CHANGE_SINGULAR_FILTER'
export const CLEAR_DEVICES = 'cylera/custom_attributes/CLEAR_DEVICES'
export const SET_DEVICES_SORT = 'cylera/custom_attributes/SET_DEVICES_SORT'
export const SET_DEVICES_FILTERS =
  'cylera/custom_attributes/SET_DEVICES_FILTERS'

const attributesApi = apiAction('custom_attribute')

export const getCustomAttributesList = filterValues => {
  const { query } = filterValues || {}
  return attributesApi('list', { query })
}

export function changeAttributeFilter(filter, value) {
  return dispatch => {
    dispatch({
      type: CHANGE_ATTRIBUTE_FILTER,
      data: {
        filter: filter,
        value: value
      }
    })
  }
}

export function getCustomAttributeDetails(id) {
  return attributesApi('details', { label_uuid: id })
}

export function updateCustomAttributeDetails(
  id,
  { name, description } = {},
  { onSuccess = () => {}, onError = () => {} } = {}
) {
  return attributesApi(
    'details',
    { label_uuid: id, label: name, description },
    {
      method: 'PUT',
      success: onSuccess,
      failure: onError
    }
  )
}

export function deleteCustomAttribute(id, { onSuccess, onError } = {}) {
  return attributesApi(
    'details',
    { label_uuid: id },
    { method: 'DELETE', success: onSuccess, failure: onError }
  )
}

export function clearDevices() {
  return dispatch => {
    dispatch({
      type: CLEAR_DEVICES
    })
  }
}

export function getDeviceFilterOptions(attributeUuid) {
  return attributesApi('devices/filter_options', { label_uuid: attributeUuid })
}

export function getCustomAttributesDevices({
  attributeUuid,
  filters,
  page,
  pageSize,
  sortKey,
  sortDirection,
  query,
  infinite,
  cacheable = false
}) {
  return attributesApi(
    'devices',
    {
      page,
      page_size: pageSize,
      sort_key: sortKey,
      sort_direction: sortDirection,
      ...filters.reduce(
        (acc, filter) =>
          filter
            ? {
                ...acc,
                [filter.value]: JSON.stringify(filter.getQuery())
              }
            : acc,
        {}
      ),
      query,
      label_uuid: attributeUuid
    },
    { infinite, cacheable }
  )
}

export function exportCustomAttributeDevices({
  attributeUuid,
  filters,
  query
}) {
  return attributesApi(`${attributeUuid}/devices/export`, {
    filters,
    query
  })
}

export function setDevicesSort(sortKey, sortDirection) {
  return dispatch => {
    dispatch({
      type: SET_DEVICES_SORT,
      data: {
        sortKey,
        sortDirection
      }
    })
  }
}

export function setDevicesFilters(filters) {
  return dispatch => {
    dispatch({
      type: SET_DEVICES_FILTERS,
      data: {
        filters
      }
    })
  }
}

export function updateCustomAttributeValues(
  { attributeUuid, deviceIds, value, selectAll } = {},
  { onSuccess, onError } = {}
) {
  return attributesApi(
    'devices',
    {
      label_uuid: attributeUuid,
      device_uuids: deviceIds,
      value,
      all_devices: selectAll
    },
    { method: 'PUT', success: onSuccess, failure: onError }
  )
}

export function removeAttributeFromDevices(
  { attributeUuid, deviceIds, selectAll } = {},
  { onSuccess, onError } = {}
) {
  return attributesApi(
    'devices',
    {
      label_uuid: attributeUuid,
      device_uuids: deviceIds,
      all_devices: selectAll
    },
    { method: 'DELETE', success: onSuccess, failure: onError }
  )
}
